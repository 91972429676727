export default {
    Dashboard: 'Dashboard',
    PricePlans: 'Price Plans',
    Users: 'Users',
    Videos: 'Videos',
    Guides: 'Guides',
    SocialGraphic: 'Social Graphic',
    Infographics: 'Infographics',
    Marketreports: 'Market reports',
    Tags: 'Tags',
    Industries: 'Industries',
}
