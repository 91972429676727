export default {
    'Select Plan': 'Select Plan',
    'Select': 'Select',
    'Remove': 'Remove',
    'Youre subscribing to': 'You\'re subscribing to',
    'Your Plan is': 'Your Plan is:',
    'No payment method foundplease add a payment method': 'No payment method found, please add a payment method.',
    'Ending In': 'Ending In:',
    'Exp': 'Exp:',
    'Card Holder Name': 'Card Holder Name'
}
