import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/vue-auth'
import 'axios-progress-bar/dist/nprogress.css'
import {loadProgressBar} from 'axios-progress-bar'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import i18n from './i18n'
import _ from 'lodash';
import VueKonva from 'vue-konva'
import * as dayjs from "dayjs";
import DatetimePicker from 'vuetify-datetime-picker'
import FlagIcon from 'vue-flag-icon'

var head = document.getElementsByTagName('head')[0];
var script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://code.createjs.com/1.0.0/createjs.min.js';
head.appendChild(script);



Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.config.productionTip = false
require('./bootstrap');
loadProgressBar()

Vue.use(VuePlyr,  {plyr: {}})

Vue.use(DatetimePicker)

Vue.use(FlagIcon);

Vue.use(VueKonva)

Vue.filter('formattedDate', function(value) {
    if (!value) return null;
    return dayjs(value).format('DD/MM/YYYY');
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
