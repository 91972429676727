export default {
    'Individual Slides': 'Персональные слайды',
    'Edit in PowerPoint': 'Редактирование в PowerPoint',
    'Download a PDF': 'Скачать PDF-файл',
    'Listen to the Audio': 'Прослушайте аудио',
    'Close': 'Закрыть',
    'Go to monthly market reports': 'Перейти к ежемесячным рыночным отчетам',
    'Previous Editions': 'Предыдущие издания',
    'No records found with': 'Никаких записей, найденных с',
    'language': 'язык',
    'Click on a slide to view a larger version of it To download an individual slide click the Download button to the right of the selected slide': 'Нажмите на слайд, чтобы просмотреть его увеличенную версию. Чтобы загрузить отдельный слайд, нажмите кнопку "Загрузить" справа от выбранного слайда.',
}
