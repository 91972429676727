import languages from "./en/languages";
import content_types from "./en/content_types";
import menu from "./en/menu";
import menuAdmin from "./en/menuAdmin";
import billings from "./en/billings";
import videos from "./en/videos";
import guides from "./en/guides";
import infographics from "./en/infographics";
import market from "./en/market";
import social from "./en/social";
import other from "./en/other";

export default {
    languages,
    content_types,
    menu,
    menuAdmin,
    billings,
    videos,
    guides,
    infographics,
    market,
    social,
    other
}
