export default {
    'Close': 'Закрыть',
    'Login': 'Авторизоваться',
    'Update': 'Обновить',
    'Reset': 'Сбросить',
    'Forgot Password': 'Забыли пароль?',
    'Sign Up': 'Зарегистрироваться',
    'Home': 'Главная',
    'About': 'О нас',
    'Password Reset': 'Сброс пароля',
    'Welcome': 'Добро пожаловать',
    'Dashboard': 'Приборная панель',
    'Personalization Profile': 'Персональный профиль',
    'Logout': 'Выйти',
    'delete': 'удалить',
    'remove': 'Удалить',
    'Confirmation': 'Подтверждение',
    'Are you sure you want to delete': 'Вы уверены, что хотите удалить',
    'This cannot be undone': 'Это не может быть отменено',
    'Cancel': 'Отмена',
    'Confirm': 'Подтвердить',
    'Updating profile': 'Обновление профиля',
    'Logo': 'Логотип',
    'Photo': 'Фото',
    'Personal Info': 'Личная информация',
    'Contact Info': 'Контактная информация',
    'Social Profiles': 'Социальные профили',
    'Bio & Compliance Info': 'Ваша биография и другая информация о Вас',
    'Bind facebook account': 'Привязать учетную запись facebook',
    'Photo & Logo': 'Фото и логотип',
}
