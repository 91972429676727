import languages from "./ru/languages";
import content_types from "./ru/content_types";
import menu from "./ru/menu";
import menuAdmin from "./ru/menuAdmin";
import billings from "./ru/billings";
import videos from "./ru/videos";
import guides from "./ru/guides";
import infographics from "./ru/infographics";
import market from "./ru/market";
import social from "./ru/social";
import other from "./ru/other";

export default {
    languages,
    content_types,
    menu,
    menuAdmin,
    billings,
    videos,
    guides,
    infographics,
    market,
    social,
    other
}
