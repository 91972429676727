export default {
    Dashboard: 'Приборная панель',
    PricePlans: 'Тарифные планы',
    Users: 'Пользователи',
    Videos: 'Видео',
    Guides: 'Инструкции',
    Infographics: 'Инфографика',
    SocialGraphic: 'Социальный график',
    Marketreports: 'Отчеты рынка',
    Tags: 'Теги',
    Industries: 'Отрасли',
}
