
export default [
    {
        path: '/dashboard',
        name: 'dashboard.index',
        component: () => import('../views/dashboard/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/profile',
        name: 'dashboard.profile',
        component: () => import('../views/dashboard/Profile.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing',
        name: 'dashboard.billing',
        component: () => import('../views/dashboard/billing/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing/select-plan',
        name: 'dashboard.billing.select-plan',
        component: () => import('../views/dashboard/billing/SelectPlan.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/billing/checkout',
        name: 'dashboard.billing.checkout',
        component: () => import('../views/dashboard/billing/Checkout.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/videos',
        name: 'dashboard.videos',
        component: () => import('../views/dashboard/videos/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/videos/:id',
        name: 'dashboard.videos.show',
        component: () => import('../views/dashboard/videos/Show.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/guides',
        name: 'dashboard.guides',
        component: () => import('../views/dashboard/guides/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/guides/:id',
        name: 'dashboard.guides.show',
        component: () => import('../views/dashboard/guides/Show.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/infographics',
        name: 'dashboard.infographics',
        component: () => import('../views/dashboard/infographics/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/infographics/:id',
        name: 'dashboard.infographics.show',
        component: () => import('../views/dashboard/infographics/Show.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/social-graphics',
        name: 'dashboard.social-graphics',
        component: () => import('../views/dashboard/social-graphics/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/social-graphics/:id',
        name: 'dashboard.social-graphics.show',
        component: () => import('../views/dashboard/social-graphics/Show.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/personalization',
        name: 'dashboard.personalization',
        component: () => import('../views/dashboard/Personalization.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/market-reports',
        name: 'dashboard.market-reports',
        component: () => import('../views/dashboard/market-reports/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/market-reports/:id',
        name: 'dashboard.market-reports.show',
        component: () => import('../views/dashboard/market-reports/Show.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/tags',
        name: 'dashboard.tags',
        component: () => import('../views/dashboard/tags/Index.vue'),
        meta: { auth: true }
    },
    {
        path: '/dashboard/tags/:id',
        name: 'dashboard.tags.show',
        component: () => import('../views/dashboard/tags/Show.vue'),
        meta: { auth: true }
    },
]
