export default {
    'Individual Slides': 'Individual Slides',
    'Edit in PowerPoint': 'Edit in PowerPoint',
    'Download a PDF': 'Download a PDF',
    'Listen to the Audio': 'Listen to the Audio',
    'Close': 'Close',
    'Go to monthly market reports': 'Go to monthly market reports',
    'Previous Editions': 'Previous Editions',
    'No records found with': 'No records found with',
    'language': 'language',
    'Click on a slide to view a larger version of it To download an individual slide click the Download button to the right of the selected slide': 'Click on a slide to view a larger version of it. To download an individual slide, click the “Download” button to the right of the selected slide.',
}
