export default {
    'Close': 'Close',
    'Login': 'Login',
    'Update': 'Update',
    'Reset': 'Reset',
    'Forgot Password': 'Forgot Password?',
    'Sign Up': 'Sign Up',
    'Home': 'Home',
    'About': 'About',
    'Password Reset': 'Password Reset',
    'Welcome': 'Welcome',
    'Dashboard': 'Dashboard',
    'Personalization Profile': 'Personalization Profile',
    'Logout': 'Logout',
    'delete': 'delete',
    'remove': 'remove',
    'Confirmation': 'Confirmation',
    'Are you sure you want to delete': 'Are you sure you want to delete',
    'This cannot be undone': 'This cannot be undone',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'Updating profile': 'Updating profile',
    'Logo': 'Logo',
    'Photo': 'Photo',
    'Personal Info': 'Personal Info',
    'Contact Info': 'Contact Info',
    'Social Profiles': 'Social Profiles',
    'Bio & Compliance Info': 'Bio & Compliance Info',
    'Bind facebook account': 'Bind facebook account',
    'Photo & Logo': 'Photo & Logo',
}
