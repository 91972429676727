
export default [
    {
        path: '/admin',
        name: 'admin.index',
        component: () => import('../views/admin/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/profile',
        name: 'admin.profile',
        component: () => import('../views/admin/Profile.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans',
        name: 'admin.price-plans.index',
        component: () => import('../views/admin/price-plans/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/create',
        name: 'admin.price-plans.create',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/price-plans/:id',
        name: 'admin.price-plans.edit',
        component: () => import('../views/admin/price-plans/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users',
        name: 'admin.users.index',
        component: () => import('../views/admin/users/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/create',
        name: 'admin.users.create',
        component: () => import('../views/admin/users/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'admin.users.edit',
        component: () => import('../views/admin/users/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/videos',
        name: 'admin.videos.index',
        component: () => import('../views/admin/videos/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/videos/create',
        name: 'admin.videos.create',
        component: () => import('../views/admin/videos/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/videos/:id',
        name: 'admin.videos.show',
        component: () => import('../views/admin/videos/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/guides',
        name: 'admin.guides.index',
        component: () => import('../views/admin/guides/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/guides/create',
        name: 'admin.guides.create',
        component: () => import('../views/admin/guides/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/guides/:id',
        name: 'admin.guides.show',
        component: () => import('../views/admin/guides/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/infographics',
        name: 'admin.infographics.index',
        component: () => import('../views/admin/infographics/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/infographics/create',
        name: 'admin.infographics.create',
        component: () => import('../views/admin/infographics/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/infographics/:id',
        name: 'admin.infographics.show',
        component: () => import('../views/admin/infographics/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/social-graphics',
        name: 'admin.social-graphics.index',
        component: () => import('../views/admin/social-graphics/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/social-graphics/create',
        name: 'admin.social-graphics.create',
        component: () => import('../views/admin/social-graphics/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/social-graphics/:id',
        name: 'admin.social-graphics.show',
        component: () => import('../views/admin/social-graphics/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/market-reports',
        name: 'admin.market-reports.index',
        component: () => import('../views/admin/market-reports/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/market-reports/create',
        name: 'admin.market-reports.create',
        component: () => import('../views/admin/market-reports/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/market-reports/:id',
        name: 'admin.market-reports.show',
        component: () => import('../views/admin/market-reports/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags',
        name: 'admin.tags.index',
        component: () => import('../views/admin/tags/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags/create',
        name: 'admin.tags.create',
        component: () => import('../views/admin/tags/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/tags/:id',
        name: 'admin.tags.show',
        component: () => import('../views/admin/tags/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },

    {
        path: '/admin/industries',
        name: 'admin.industries.index',
        component: () => import('../views/admin/industries/Index.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/industries/create',
        name: 'admin.industries.create',
        component: () => import('../views/admin/industries/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
    {
        path: '/admin/industries/:id',
        name: 'admin.industries.show',
        component: () => import('../views/admin/industries/Form.vue'),
        meta: {
            auth: 'admin'
        }
    },
];
