export default {
    btncls: 'Close',
    btnlg: 'Login',
    btnupd: 'Update',
    btnrs: 'Reset',
    btnfp: 'Forgot Password',
    btnsup: 'Sign Up',
    btnhm: 'Home',
    btnabt: 'About',
    strpr: 'Password Reset',
    wlcm: 'Welcome',
    dbrd: 'Dashboard',
    pprfl: 'Personalization Profile',
    lgt: 'Logout',
    dlt: 'delete',
    rmv: 'remove',
    cnfm: 'Confirmation',
    strothone: 'Are you sure you want to delete',
    strothtwo: 'This cannot be undone',
    cncl: 'Cancel',
    cnfrm: 'Confirm',
    updpr: 'Updating profile',
    lg: 'Logo',
    pht: 'Photo',
    persinf: 'Personal Info',
    continf: 'Contact Info',
    socpr: 'Social Profiles',
    bcinf: 'Bio & Compliance Info',
    btnfcb: 'Bind facebook account',
    phtlg: 'Photo & Logo',
}
