export default {
    en: 'Английский',
    es: 'Испанский',
    ru: 'Русский',
    zh: 'Китайский',
    jp: 'Японский',
    de: 'Немецкий',
    pt: 'Португальский',
    fr: 'Французский',
    ar: 'Арабский',
    kr: 'Корейский',
    ms: 'Малайский',
}
