export default {
    Dashboard: 'Dashboard',
    Billing: 'Billing',
    SelectPlan: 'Select Plan',
    Videos: 'Videos',
    Guides: 'Guides',
    Infographics: 'Infographics',
    SocialGraphic: 'Social Graphic',
    Marketreports: 'Market reports',
    BrowseByTopic: 'Browse by Topic',
}
