export default {
    'Select Plan': 'Выбрать план',
    'Select': 'Выбрать',
    'Remove': 'Удалить',
    'Youre subscribing to': 'Вы подписываетесь на',
    'Your Plan is': 'Ваш план:',
    'No payment method foundplease add a payment method': 'Способ оплаты не найден, добавьте способ оплаты.',
    'Ending In': 'Кончающийся на:',
    'Exp': 'Опыт:',
    'Card Holder Name': 'Имя владельца карты',
}
